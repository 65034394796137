import * as React from 'react';
import Card from '@components/Card';
//import AlertBanner from '@components/AlertBanner';
import Header, { HEADER_TYPE } from '@components/Header';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
//import NotificationBell from '@components/NotificationBell';
import { Outlet } from 'react-router-dom';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@contexts/UserContext';
import useUserDiscount from '@hooks/useUserDiscount';
import useGetUserInfo from '@hooks/useUserInfo';
//import useGetUserAlerts from '@hooks/useUserAlerts';
//import ModalAlert from '@components/ModalAlert';
import ModalDiscount from '@components/ModalDiscount';
import ModalDiscountReseller from '@components/ModalDiscountReseller';

export default function Dashboard() {
  //const [modalAlertIsOpen, setModalAlertIsOpen] = React.useState(false);
  const [modalDiscountIsOpen, setModalDiscountIsOpen] = React.useState(false);
  const [modalResellerIsOpen, setModalResellerIsOpen] = React.useState(false);

  /*const onOpenModalAlertFn = React.useCallback(() => {
    setModalAlertIsOpen(true);
  }, []);*/

  /*const onCloseModalAlertFn = React.useCallback(() => {
    setModalAlertIsOpen(false);
  }, []);*/

  const onOpenModalDiscountFn = React.useCallback(() => {
    setModalDiscountIsOpen(true);
  }, []);

  const onCloseModalDiscountFn = React.useCallback(() => {
    setModalDiscountIsOpen(false);
  }, []);

  const onOpenModalResellerlFn = React.useCallback(() => {
    setModalResellerIsOpen(true);
  }, []);

  const onCloseModalResellerFn = React.useCallback(() => {
    setModalResellerIsOpen(false);
  }, []);

  const navigateToSupremoConsole = () => {
    window.open(t('links:console.home'), '_blank');
  };

  const { t } = useTranslation([
    'common',
    'routes',
    'dashboard'
  ]);

  const { isLoggedIn } = React.useContext(UserContext);
  const { data } = useGetUserInfo(isLoggedIn);

  const { data: discount } = useUserDiscount(isLoggedIn);

  //const { data: alertsData } = useGetUserAlerts(isLoggedIn);

  return (
    <>
      {/*<ModalAlert isOpen={modalAlertIsOpen} onClose={onCloseModalAlertFn} title={''} content={alertsData?.content || ''} />*/}

      <ModalDiscount isOpen={modalDiscountIsOpen} onClose={onCloseModalDiscountFn} />

      <ModalDiscountReseller isOpen={modalResellerIsOpen} onClose={onCloseModalResellerFn} />

      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage isCentered>
        <LayoutSection isCentered>
          <h2>
            {t('common:navigation.hello', {
              name: data?.username,
            })}
          </h2>
          <div className={styles.alert}>
            {/* {alertsData && alertsData.content?.length > 0 &&
              <AlertBanner onClick={onOpenModalAlertFn}>
                <div className={styles.alertContent}>
                  <NotificationBell isActive />
                  <div dangerouslySetInnerHTML={{ __html: alertsData.content.replace(/\\"/g, '"').replace(/\\&quot;/g, '') }}></div>
                </div>
              </AlertBanner>
            } */}
          </div>
          <div className={styles.blocks}>
            <Link to={t('routes:purchases')}>
              <Card>
                <div className={styles.card}>
                  <span className="icon-file-search"></span>
                  <h4>{t('dashboard:purchases.title')}</h4>
                  <p>{t('dashboard:purchases.description')}</p>
                </div>
              </Card>
            </Link>
            <Link to={t('routes:license')}>
              <Card>
                <div className={styles.card}>
                  <span className="icon-file"></span>
                  <h4>{t('dashboard:licenses.title')}</h4>
                  <p>{t('dashboard:licenses.description')}</p>
                </div>
              </Card>
            </Link>
            <Card
              onClick={
                data?.isReseller ? onOpenModalResellerlFn : onOpenModalDiscountFn
              }
            >
              <div className={styles.card}>
                <span className="icon-percent"></span>
                <h4>{t('dashboard:discounts.title')}</h4>
                <p>{t('dashboard:discounts.description')}</p>
                {discount && discount?.amount > 0 &&
                  <>
                    <br />
                    <p className={styles.discountLabel}>{t('dashboard:discounts.currentDiscount', { 'amount': discount?.amount })}</p>
                  </>
                }
              </div>
            </Card>
            <Card onClick={navigateToSupremoConsole}>
              <div className={styles.card}>
                <span className="icon-app-window"></span>
                <h4>{t('dashboard:console.title')}</h4>
                <p>{t('dashboard:console.description')}</p>
              </div>
            </Card>
            <Link to={t('routes:payments')}>
              <Card>
                <div className={styles.card}>
                  <span className="icon-credit-card"></span>
                  <h4>{t('dashboard:paymentMethod.title')}</h4>
                  <p>{t('dashboard:paymentMethod.description')}</p>
                </div>
              </Card>
            </Link>
            <Link to={t('routes:account')}>
              <Card>
                <div className={styles.card}>
                  <span className="icon-user-circle"></span>
                  <h4>{t('dashboard:myAccount.title')}</h4>
                  <p>{t('dashboard:myAccount.description')}</p>
                </div>
              </Card>
            </Link>
          </div>
          {data && data.isReseller &&
            <Link to={t('dashboard:guide.link')} target="_blank" className={styles.dashboardGuideLink} onClick={(e) => e.stopPropagation()}>
              {t('dashboard:guide.label')}
            </Link>
          }
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}
